import React, { useState, useCallback, useEffect } from "react";
import { checkProjectType } from "constants/projectsType";
import styles from "./index.module.scss";
import Login from "components/Login";
import useSetState from "customHooks/setState";
import { useStore, StoreTypes } from "context";
import * as actionTypes from "constants/actionTypes";
import { useLogin } from "util/login";
import ClassSelect from "components/ClassSelect";
import UserSelect from "components/UserSelect";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import classnames from "classnames";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
var bodyClass;

const Header = () => {
  const router = useStore(StoreTypes.router);
  const [open, setOpen] = useState(false);
  const [isHeaderBg, setIsHeaderBg] = useState(false);
  const [
    { isClassSeletPannel, nowHeadSelectListName, educationInfo },
    globalDispatch,
  ] = useStore(StoreTypes.global);
  const [{ token, userId, name }, userDispatch] = useStore(StoreTypes.user);

  useEffect(() => {
    bodyClass = document.getElementById("root");
    const scrollHandler = (e) => {
      var top = e.target.scrollTop;
      if (top < 10) {
        setIsHeaderBg(false);
      } else {
        setIsHeaderBg(true);
      }
    };
    bodyClass.addEventListener("scroll", scrollHandler);
    return () => {
      bodyClass.removeEventListener("scroll", scrollHandler);
    };
  });
  

  const classSelect = useCallback(() => {
    if (!isClassSeletPannel && educationInfo) {
      globalDispatch({
        type: actionTypes.SET_CLASS_SELECT_PANNEL,
        isClassSeletPannel: true,
      });
    } else {
      globalDispatch({
        type: actionTypes.SET_CLASS_SELECT_PANNEL,
        isClassSeletPannel: false,
      });
    }
  }, [educationInfo, globalDispatch, isClassSeletPannel]);

	const goMainView = () => {
		router.history.push(`/MainView`);
	};
	const changeVersionNumColor = (isHeaderBg) => {
		return !isHeaderBg && (router.location.pathname === "/MainPage");
	};
	return (
		<>
			<Row
				className={classnames(styles.head, {
					[styles.show]: isHeaderBg,
				})}
			>
				<Col></Col>
				<Col>
					<img
						// eslint-disable-next-line react/style-prop-object
						className={styles.nanilogoBox}
						onClick={() => goMainView()}
						src={`./assets/img/nanistudy/nanistudy_logo-160X36.png`}
						alt="nanistudy"
					/>
				</Col>
				<Col>
					<div
						className={classnames(styles.selectSubject, {
							[styles.show]: isClassSeletPannel,
						})}
						onClick={() => classSelect()}
					>
						<div className={styles.selectButton}>選擇科目</div>
						<KeyboardArrowDownIcon sx={{color: isClassSeletPannel ? "#41415B" : "#FFD732"}}/>
					</div>
				</Col>
				<Col></Col>
				<Col>
					<UserSelect open={open} setOpen={setOpen} />
				</Col>
				<Col className={styles.versionNum}>
					<div className={classnames(styles.versionNumText, {
						[styles.show]: changeVersionNumColor(isHeaderBg),
					})}>{`v${process.env.REACT_APP_VERSION}`}</div>
				</Col>
				{<Login open={open} setOpen={setOpen} />}
				{isClassSeletPannel && <ClassSelect />}
			</Row>
			{router.location.pathname === "/MainPage" && checkProjectType() && <div className={styles.launchTimetable}>
				<div className={styles.launchTimetableText}>
					<svg width="42" height="20" viewBox="0 0 42 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect width="42" height="20" rx="4" fill="#EC7963" />
						<path d="M7.36757 15H9.18757V11.139C9.18757 10.034 9.04457 8.825 8.95357 7.785H9.01857L9.99357 9.852L12.7756 15H14.7256V5.38H12.9056V9.215C12.9056 10.307 13.0486 11.581 13.1526 12.582H13.0876L12.1256 10.502L9.33057 5.38H7.36757V15ZM17.1049 15H23.1499V13.388H19.0289V10.814H22.4089V9.202H19.0289V6.979H23.0069V5.38H17.1049V15ZM26.0129 15H28.3659L29.4189 10.242C29.5749 9.488 29.7049 8.747 29.8349 8.019H29.8869C30.0039 8.747 30.1469 9.488 30.3029 10.242L31.3819 15H33.7739L35.5549 5.38H33.7219L32.9679 10.073C32.8249 11.074 32.6819 12.101 32.5389 13.141H32.4739C32.2659 12.101 32.0709 11.061 31.8629 10.073L30.7449 5.38H29.1069L27.9889 10.073C27.7809 11.074 27.5729 12.114 27.3779 13.141H27.3259C27.1829 12.114 27.0269 11.087 26.8839 10.073L26.1169 5.38H24.1539L26.0129 15Z" fill="white" />
					</svg>
					<span>111下學年度</span>
					<span>配合學校進度，影音及測驗資源將陸續上架！</span>
				</div>
				<img src="./assets/img/launchTimetable.jpg" alt="上架時程" />
				<a className={styles.timetableLink} href="https://tutor.oneclass.com.tw/onestudyupdate_oc/" target="_blank" rel="noreferrer">查看時間表</a>
				<div className={styles.closeBtn} onClick={(e) => e.target.parentNode.style.display = 'none'}></div>
			</div>}
		</>
	);
};
export default Header;
