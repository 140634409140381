import React, { useState, useCallback, useEffect } from "react";
import styles from "./index.module.scss";
import useSetState from "customHooks/setState";
import { useStore, StoreTypes } from "context";
import DialogModule from "components/Dialogs/";
import Header from "components/NaniMainPage/Header";
import Footer from "components/Footer";
import { Beforeunload } from 'components/Beforeunload';
import Page1 from "./Page1";
import SelectVolumeCard from "components/SelectVolumeCard";
import * as actionTypes from "constants/actionTypes";
import { checkProjectType } from "constants/projectsType";

const NaniMainPage = () => {
	const [, globalDispatch] = useStore(StoreTypes.global);

	useEffect(() => {
		globalDispatch({
			type: actionTypes.SET_NOW_HEAD_LIST_SELECT_NAME,
			nowHeadSelectListName: "",
		});

	}, [globalDispatch]);

	const scrollToAnchor = (anchorName) => {
		if (anchorName) {
			let anchorElement = document.getElementById(anchorName);
			if (anchorElement) {
				anchorElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
			}
		}
	};

	return (
		<Beforeunload>
			<div>
				<Header />
				<div className={styles.mainPage}>
					<div className={styles.page2}>
						<div className={styles.bg}>
							<img src={`./assets/img/nanistudy/NTIV004051-1.jpg`} alt="bg" />
						</div>
						<div
							className={styles.btn}
						>
							<img src={"./assets/img/nanistudy/enterbtn.svg"} alt="button2"
								onClick={() => scrollToAnchor("SelectVolumeCard")} />
						</div>
					</div>
					<div className={styles.page3}>
						<div className={styles.bg}>
							<img src={`./assets/img/nanistudy/NTIV004051-2.jpg`} alt="bg" />
						</div>
					</div>
					<div className={styles.page4}>
						<div className={styles.bg}>
							<img src={`./assets/img/nanistudy/NTIV004051-3.jpg`} alt="bg" />
						</div>
						<div
							className={styles.btn}
						>
							<img src={"./assets/img/nanistudy/enterbtn.svg"} alt="button2"
								onClick={() => scrollToAnchor("SelectVolumeCard")} />
						</div>
					</div>
					<SelectVolumeCard />
					<Footer />
				</div>
			</div>
		</Beforeunload>
	);
};
export default NaniMainPage;
